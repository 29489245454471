<template>
    <div class="list-box">
        <div class="info-step-header">
            <el-breadcrumb>
                <el-breadcrumb-item>京东直投</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="big-title">
            <div>所有推广计划</div>
        </div>
        <div class="main-box">
            <div class="tab-box">
                <div class="item">推广计划</div>
            </div>
            <div class="operate-box">
                <el-button class="btn-red" @click="addBtn" :disabled="planList.length > 0">新建计划</el-button>
                <el-button class="btn-red-line" @click="downloadBtn">下载素材</el-button>
            </div>
            <el-table :data="planList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%"
                      :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}"
                      :header-cell-style="{fontWeight: 'normal', color: '#14141C', background: '#F5F5F5',height: '60px'}">
                <el-table-column label="计划名称" prop="plan_name"></el-table-column>
                <el-table-column label="媒体类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.media_type === 1">腾讯</span>
                        <span v-if="scope.row.media_type === 2">头条</span>
                        <span v-if="scope.row.media_type === 3">百度</span>
                        <span v-if="scope.row.media_type === 4">快手</span>
                    </template>
                </el-table-column>
                <el-table-column label="出价方式" prop="offer_type">
                    <template slot-scope="scope">
                        <div v-if="scope.row.offer_type">
                            <span v-if="scope.row.media_type === 1 && scope.row.promote_type === 1">
                                <span v-if="scope.row.offer_type === 1">CPC</span>
                                <span v-if="scope.row.offer_type === 2">CPM</span>
                                <span v-if="scope.row.offer_type === 3">eUV</span>
                            </span>
                                <span v-if="(scope.row.media_type === 1 && scope.row.promote_type === 2) ||
                                        (scope.row.media_type === 1 && scope.row.promote_type === 4)">
                                <span v-if="scope.row.offer_type === 1">CPC</span>
                                <span v-if="scope.row.offer_type === 2">CPM</span>
                            </span>
                                <span v-if="scope.row.media_type === 2 && scope.row.promote_type === 1">
                                 <span v-if="scope.row.offer_type === 1">CPC</span>
                                 <span v-if="scope.row.offer_type === 2">CPM</span>
                                 <span v-if="scope.row.offer_type === 3">tCPA</span>
                                 <span v-if="scope.row.offer_type === 4">eCPC</span>
                                 <span v-if="scope.row.offer_type === 5">eUV</span>
                            </span>
                                <span v-if="(scope.row.media_type === 2 && scope.row.promote_type === 2) ||
                                        (scope.row.media_type === 3 && scope.row.promote_type === 1) ||
                                        (scope.row.media_type === 3 && scope.row.promote_type === 2)">
                                 <span v-if="scope.row.offer_type === 1">CPC</span>
                                 <span v-if="scope.row.offer_type === 2">CPM</span>
                                 <span v-if="scope.row.offer_type === 3">tCPA</span>
                            </span>
                                <span v-if="scope.row.media_type === 3 && scope.row.promote_type === 3">
                                <span v-if="scope.row.offer_type === 1">CPC</span>
                                <span v-if="scope.row.offer_type === 2">CPM</span>
                                <span v-if="scope.row.offer_type === 3">tCPA</span>
                                <span v-if="scope.row.offer_type === 4">eUV</span>
                            </span>
                                <span v-if="scope.row.media_type === 4 && scope.row.promote_type === 1">
                                <span v-if="scope.row.offer_type === 1">CPC</span>
                                <span v-if="scope.row.offer_type === 2">tCPA</span>
                                <span v-if="scope.row.offer_type === 3">eUV</span>
                            </span>
                                <span v-if="scope.row.media_type === 4 && scope.row.promote_type === 2">
                                <span v-if="scope.row.offer_type === 1">CPC</span>
                                <span v-if="scope.row.offer_type === 2">tCPA</span>
                            </span>
                        </div>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column label="每日限额" prop="limit">
                    <template slot-scope="scope">
                        <span v-if="!scope.row.limit">-</span>
                        <span v-else>{{scope.row.limit}}元/天</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150px">
                    <template slot-scope="scope">
                        <el-link :underline="false" @click="editBtn(scope.row.id)" type="success">编辑</el-link>
                        <el-link :underline="false" @click="delBtn(scope.row.id)" type="danger">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import { operationsGetTrain, operationsDelStuOperations } from '@/utils/apis.js'

    import CustomBudgetDrawer from '@/components/student/exam/infographicPromotion/CustomBudgetDrawer'
    import CustomTimeDrawer from '@/components/student/exam/infographicPromotion/CustomTimeDrawer'
    export default {
        components: {
            CustomBudgetDrawer,
            CustomTimeDrawer,
        },
        data() {
            return {
                //计划列表
                planList: [],
                //考试id
                isExamOrTrain: localStorage.getItem('examId') ? localStorage.getItem('examId') : null,
                file_download:this.$route.query.file_download
            }
        },
        created() {
            this.getList();
        
        },
        methods: {
            //获取列表
            getList() {
                let params = {
                    op_id: this.$route.query.op_id, // 实操题试题id
                }
                if (this.isExamOrTrain) {
                    params.type = '2';
                    params.eaxm_id = this.isExamOrTrain;
                } else {
                    params.type = '1';
                    params.course_id = this.$route.query.course_id;
                }
                operationsGetTrain(params).then((res) => {
                    if (res.data.length === 0) {
                        this.planList = [];
                    } else {
                        let obj = {
                            id: res.data.id,
                            plan_name: res.data.plan.plan_name,
                            media_type: res.data.plan.media_type,
                            promote_type: res.data.plan.promote_type,
                            offer_type: res.data.plan.offer_type,
                            limit: res.data.plan.limit,
                        }
                        this.planList.push(obj);
                    }
                }).catch((err) => {})
            },
            //新建计划
            addBtn() {
                this.$router.push({
                    path: '/student/JDDirectInvestment/stepOne',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                        step: 1
                    }
                })
            },
            //下载素材
            downloadBtn() {
              window.open(this.file_download, '_blank')
            },
            // 编辑
            editBtn(id) {
                if (this.isExamOrTrain) {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            step: 1,
                            editId: id,
                            eaxm_id: this.isExamOrTrain
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/JDDirectInvestment/stepOne',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            step: 1,
                            editId: id
                        }
                    })
                }

            },
            // 删除
            delBtn(id) {
                this.$confirm(`您确定要删除该计划吗？`,'操作确认', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'btn-red-line',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        id: id
                    }
                    operationsDelStuOperations(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    .list-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 40px;
    }
    .info-step-header {
        padding: 22px 0 0;
    }
    .big-title {
        font-size: 20px;
        line-height: 1;
        margin: 20px 0;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
    }
    .main-box {
        height: 1%;
        flex: 1;
        background: #fff;
        margin-bottom: 50px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .tab-box {
            font-size: 20px;
            color: #FD4446;
            line-height: 1;
            .item {
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -10px;
                    height: 2px;
                    background: #FD4446;
                }

            }
        }
        .operate-box {
            margin-top: 30px;
            display: flex;
        }
    }
</style>